/** @format */

import React, { useEffect, useState } from "react";
import blogData from '../components/data';
import { useParams } from "react-router-dom";

const BlogDetails = () => {
  const { id } = useParams(); // Get the id parameter from the URL

  const [blogPost, setBlogPost] = useState(null);

  useEffect(() => {
    // Find the blog post with matching id from blogData
    const post = blogData.find((post) => post.id === parseInt(id));
    setBlogPost(post); // Set the blog post state
  }, [id]); // Re-run when id changes

  if (!blogPost) {
    return <p>Loading...</p>; // Display a loading message while fetching data
  }


  return (
    <>
      <div
        class="min-h-[40vh] flex items-center bg-secondary bg-cover bg-center"
        style={{
          backgroundImage: "url(./bg.png)",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          justifyContent: "flex-start",
        }}
      >
        <div class="text-white text-left px-12 py-2">
          <h1 class="md:text-6xl text-3xl font-bold mb-4 mt-8">Blog Details</h1>
          <div className="flex space-x-6">
            {" "}
            <a href="/" class="text-xl text-white no-underline  mb-4 block">
              Home
            </a>
            <div class="text-4xl"> → </div>
            <a href="/blog" class="text-xl text-white no-underline mb-4 block">
              Blog
            </a>
          </div>
        </div>
      </div>
      <div class="container mx-auto mt-8 mb-16 w-3/5">
        <div class="text-center">
          <img
                  src={blogPost.imageUrl}
                  alt="Blog Image"
            class="w-full mb-4"
          />

          <p class="text-gray-500 mb-4">{blogPost.author} &bull; {blogPost.date}</p>

          <h1 class="text-3xl md:text-4xl font-bold mb-4">
         {blogPost.title}         </h1>

          <div class="mb-8">
            <p class="text-gray-700 mb-4">
              {blogPost.content}
            </p>

            <blockquote class="italic text-gray-600 border-l-4 border-primary px-4 py-2 mb-4">
{blogPost.quote}
              <footer class="mt-2">- {blogData.foot}</footer>
            </blockquote>

            <p class="text-gray-700 mb-4">
{blogPost.secondContent}            </p>

            {/* <img
              src="https://via.placeholder.com/600x400"
              alt="Second Image"
              class="w-full mb-4"
            /> */}


          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetails;
