/** @format */

import React from "react";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <>
      <div
        class="min-h-[40vh] flex items-center bg-secondary bg-cover bg-center"
        style={{
          backgroundImage: "url(./about.webp)",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          justifyContent: "flex-start", 
        }}
      >
        <div class="text-white text-left px-12 py-2">
          <h1 class="text-4xl font-bold mb-4 mt-8">About Us</h1>
          <div className="flex space-x-6">
            {" "}
            <a href="/" class="text-xl text-white no-underline  mb-4 block">
              Home
            </a>
            <div class="text-4xl"> → </div>
            <a href="/about" class="text-xl text-white no-underline mb-4 block">
              About
            </a>
          </div>
        </div>
      </div>
      <section className="p-4">
        <div className="flex flex-col md:flex-row justify-center items-center">
          {/* Column 1: Image */}
          <div className="w-full md:w-2/5 p-4 mb-4 md:mb-0">
            <img
              src="recovery.webp"
              alt="Crypto Image"
              className="w-full h-auto"
            />
          </div>

          {/* Column 2: Text Content */}
          <div className="w-full md:w-2/5 p-4 flex flex-col justify-start items-start text-center md:text-left">
            <h1 className="text-2xl md:text-6xl font-bold mb-4 text-secondary leading-tight">
              Recovery Your Crypto More{" "}
              <span className="text-primary">Easily</span>
            </h1>

            <p className="text-base md:text-lg mb-4">
            Our company specializes in recovering lost crypto assets. We have a team of experts who are dedicated to helping individuals retrieve their digital currencies securely and efficiently. Clients rely on our professionalism and expertise to navigate the process smoothly. If you ever need assistance with recovering lost crypto assets, feel free to reach out to us!            </p>
<Link to='/contactus'>
            <button className="bg-secondary hover:bg-white hover:text-secondary text-white font-bold py-3 px-6 rounded">
              Get started
            </button>
            </Link>
          </div>
        </div>
      </section>

      <section className="flex flex-col justify-center items-center min-h-screen bg-white bg-center bg-secondary text-white">
        <div className="py-8 text-center">
          <h1 className="text-3xl md:text-6xl font-bold text-secondary">
            Why do Clients <span className="text-primary">Trust Us</span>
          </h1>
          <p className="mt-4 w-[40%] mx-auto text-lg text-secondary ">
          Clients trust us because of our expertise and commitment to ensuring a smooth recovery process. Our team of experts goes above and beyond to provide professional and secure assistance in recovering various digital currencies. We prioritize communication, keeping clients informed every step of the way, ensuring a secure and efficient recovery journey. If you ever need help with recovering lost crypto assets, we're here to assist you!
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 max-w-7xl m-auto">
          {/* Column 1 */}
          <div className="bg-white rounded-md text-center">
            <div className="bg-primary rounded-full w-24 h-24 flex justify-center items-center mx-auto mb-4">
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="4em" width="4em" xmlns="http://www.w3.org/2000/svg"><path d="M512 64L128 192v384c0 212.1 171.9 384 384 384s384-171.9 384-384V192L512 64zm312 512c0 172.3-139.7 312-312 312S200 748.3 200 576V246l312-110 312 110v330z"></path><path d="M378.4 475.1a35.91 35.91 0 0 0-50.9 0 35.91 35.91 0 0 0 0 50.9l129.4 129.4 2.1 2.1a33.98 33.98 0 0 0 48.1 0L730.6 434a33.98 33.98 0 0 0 0-48.1l-2.8-2.8a33.98 33.98 0 0 0-48.1 0L483 579.7 378.4 475.1z"></path></svg>         

            </div>
            <h4 className="text-lg font-bold">Safe and Confidential</h4>
            <p className="mt-2 text-gray-500 w-[100%] text-center m-auto">
            Clients trust us because we prioritize safety and confidentiality. Our commitment to keeping your information secure and handling your recovery process with the utmost confidentiality is what sets us apart. You can rely on us to ensure a safe and discreet experience when it comes to recovering your lost crypto assets.
            </p>
          </div>

          {/* Column 2 */}
          <div className="bg-white rounded-md text-center">
            <div className="bg-[#16C9F0] text-white rounded-full w-24 h-24 flex justify-center items-center mx-auto mb-4">
            <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 16 16"
        height="4em"
        width="4em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"></path>
      </svg>
               </div>
                 <p className="mt-2 text-gray-500 w-3/4 text-center m-auto">
               Clients trust us because we offer quick solutions. Our team is dedicated to providing fast and efficient assistance in recovering lost crypto assets. We understand the importance of a timely resolution, and our commitment to delivering swift solutions is why clients rely on us for their crypto recovery needs.
            </p>
          </div>

          {/* Column 3 */}
          <div className="bg-white rounded-md text-center">
            <div className="bg-[#A79AF4] rounded-full w-24 h-24 flex justify-center items-center mx-auto mb-4">
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="4em" width="4em" xmlns="http://www.w3.org/2000/svg"><path d="M12 2C6.486 2 2 6.486 2 12v4.143C2 17.167 2.897 18 4 18h1a1 1 0 0 0 1-1v-5.143a1 1 0 0 0-1-1h-.908C4.648 6.987 7.978 4 12 4s7.352 2.987 7.908 6.857H19a1 1 0 0 0-1 1V18c0 1.103-.897 2-2 2h-2v-1h-4v3h6c2.206 0 4-1.794 4-4 1.103 0 2-.833 2-1.857V12c0-5.514-4.486-10-10-10z"></path></svg>
            </div>
            <h4 className="text-lg font-bold">Customer Care</h4>
            <p className="mt-2 text-gray-500 w-[100%] text-center m-auto">
            Clients trust us because we provide excellent customer care. Our team is committed to offering top-notch service, ensuring that clients receive the support and attention they deserve throughout the recovery process. We prioritize customer satisfaction, and our dedication to exceptional care is why clients trust us with their crypto asset recovery needs.
            </p>
          </div>

          {/* Column 4 */}
          <div className="bg-white rounded-md text-center">
            <div className="bg-[#E79AF4] rounded-full w-24 h-24 flex justify-center items-center mx-auto mb-4">
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="4em" width="4em" xmlns="http://www.w3.org/2000/svg"><path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"></path><path d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z"></path></svg>
            </div>
            <h4 className="text-lg font-bold">Cashback Program</h4>
            <p className="mt-2 text-gray-500 w-[100%] text-center m-auto">
             Clients trust us because we offer a fantastic cashback program. Our commitment to providing a rewarding cashback experience sets us apart and builds trust with our clients. We strive to create value for our customers through our cashback program, ensuring a mutually beneficial relationship. If you ever need assistance with crypto asset recovery or want to learn more about our cashback program, feel free to reach out!
            </p>
          </div>
        </div>
      </section>
      <div class="mx-auto bg-secondary py-8">
  <div class="container grid grid-cols-1 md:grid-cols-2 gap-4">
    <div class="col-span-1 flex flex-col px-4 text-white">
      <h2 class="text-4xl md:text-6xl font-bold mb-4">When We Can’t Help</h2>
      <ul class="list-disc list-inside mb-8 pl-4">
        <li>You have no idea what your password was</li>
        <li>Your password was 15+ randomly-chosen characters long</li>
        <li>You have been scammed or your funds have been stolen</li>
      </ul>
    </div>

    <div class="col-span-1 hidden md:block">
      <img
        src="./cant_help.png"
        alt="Image"
        class="w-full h-full object-cover"
      />
    </div>
  </div>
</div>


<section className="flex flex-col justify-center items-center min-h-screen bg-cover bg-center bg-[#F6FBFF] mb-8 text-secondary" style={{ backgroundImage: "url(./work.png)" }}>
  <div className="flex flex-col lg:flex-row justify-center items-center w-full max-w-6xl mx-auto px-4 lg:px-8">
    {/* Column 1: Image */}
    <div className="lg:w-1/2 p-4">
      <img src="workbg.webp" alt="Crypto Image" className="w-full h-auto" />
    </div>

    {/* Column 2: Text Content */}
    <div className="lg:w-1/2 p-4 flex flex-col justify-start items-start">
      <h1 className="text-3xl lg:text-6xl font-bold mb-4 leading-tight lg:leading-42">
        We Work In Most Demand <span className="text-primary">Cryptocurrencies</span>
      </h1>

      <p className="text-base lg:text-lg mb-4">
      When it comes to the most in-demand cryptocurrencies, our company specializes in working with the top choices in the market. We stay updated on the latest trends and developments in the crypto world to ensure we can assist our clients effectively with the most sought-after digital assets. If you're looking for support with popular cryptocurrencies, we've got you covered!
      </p>

      {/* Cryptocurrency Icons with Text */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="flex items-center">
          <img src="./bitcoin.webp" alt="Bitcoin Image" className="w-full" />
          <p className="text-lg ml-4">Bitcoin</p>
        </div>
        <div className="flex items-center">
          <img src="./litecoin.webp" alt="Litecoin Image" className="w-full" />
          <p className="text-lg ml-4">Litecoin</p>
        </div>
        <div className="flex items-center">
          <img src="./ethereum.webp" alt="Ethereum Image" className="w-full" />
          <p className="text-lg ml-4">Ethereum</p>
        </div>
        <div className="flex items-center">
          <img src="./dash.webp" alt="Dash Image" className="w-full" />
          <p className="text-lg ml-4">Dash</p>
        </div>
      </div>

      {/* Get Started Button */}
      <Link></Link>
      <button className="bg-secondary hover:bg-secondary hover:text-white text-white font-bold mt-4 py-4 px-6 rounded">
        Get Started
      </button>
    </div>
  </div>
</section>
    </>
  );
};

export default About;
