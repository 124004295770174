import React from "react";
import FAQ from "../components/Faq";

const Pricing = () => {
  return (
    <>
    <div class="min-h-[40vh] flex items-center bg-secondary bg-cover bg-center"
    style={{
        backgroundImage: 'url(./about.webp)',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        justifyContent: 'flex-start' // Align content to the start (left)
    }}
>



    <div class="text-white text-left px-12 py-2">
        <h1 class="text-4xl font-bold mb-4 mt-8">Pricing Plan</h1>
        <div className="flex space-x-6">        <a href="/" class="text-xl text-white no-underline  mb-4 block">Home</a>
        <div class="text-4xl"> → </div>
        <a href="/pricing" class="text-xl text-white no-underline mb-4 block">Pricing</a>
        </div>

    </div>

    
</div>

<section
  className="py-4"
  style={{
    backgroundImage: 'url(./pricing.png)',
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat',
  }}
>
  <div className="container px-4">
    <div className="mb-8">
      <h2 className="text-4xl font-bold text-secondary mt-8">
        Choose Your <span className="text-primary">Price Plan</span>
      </h2>
      <p className="text-lg text-white mt-4">
        Check our price plan for what suits your wallet
      </p>
    </div>

    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
      {/* Left Column: Features (Responsive Layout) */}
      <div>
        <div className="mb-8 lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="flex items-center">
            <div className="w-8 h-8 bg-primary rounded-full flex justify-center items-center">
              <span className="text-white">1</span>
            </div>
            <p className="ml-4 mb-4">Safe and Confidential</p>
          </div>
          <div className="flex items-center">
            <div className="w-8 h-8 bg-primary rounded-full flex justify-center items-center">
              <span className="text-white">2</span>
            </div>
            <p className="ml-4  mb-4" >Recover asset in one week</p>
          </div>
          <div className="flex items-center">
            <div className="w-8 h-8 bg-primary rounded-full flex justify-center items-center">
              <span className="text-white">3</span>
            </div>
            <p className="ml-4  mb-4">No penalty if empty</p>
          </div>
          <div className="flex items-center">
            <div className="w-8 h-8 bg-primary rounded-full flex justify-center items-center">
              <span className="text-white">4</span>
            </div>
            <p className="ml-4  mb-4">Cancel contract at any time</p>
          </div>
        </div>
      </div>

      {/* Right Column: Plan Cards */}
      <div className="flex flex-col lg:flex-row gap-8">
        {/* Standard Plan Card */}
        <div className="flex-1 relative bg-white rounded-lg overflow-hidden lg:-mr-12 -mt-8 shadow-lg">
          <div className="bg-secondary py-12 px-6 rounded-t-lg" style={{ borderBottomLeftRadius: '50%', borderBottomRightRadius: '50%' }}>
            <h2 className="text-xl text-white font-bold">Standard Plan</h2>
            <p className="text-white text-4xl">15% off</p>
          </div>
          <ul className="py-4 px-6">
            <li className="flex items-center text-primary mb-2">
              <span className="h-4 w-4 bg-primary rounded-full mr-2"></span>
              No penalty if empty            </li>
            <li className="flex items-center text-primary mb-2">
              <span className="h-4 w-4 bg-primary rounded-full mr-2"></span>
              Cancel contract at any time            </li>
            <li className="flex items-center text-primary mb-2">
              <span className="h-4 w-4 bg-primary rounded-full mr-2"></span>
              Top tier data protection            </li>
          </ul>
          <button className="py-4 px-6 bg-secondary text-white text-lg mb-4 rounded-md">Let's Talk</button>
        </div>

        {/* Premium Plan Card */}
        <div className="flex-1 relative bg-white rounded-lg overflow-hidden shadow-lg">
          <div className="bg-primary py-12 px-6 rounded-t-lg" style={{ borderBottomLeftRadius: '50%', borderBottomRightRadius: '50%' }}>
            <h2 className="text-xl text-white font-bold">Premium Plan</h2>
            <p className="text-white text-4xl">20% off</p>
          </div>
          <ul className="py-4 px-6">
            <li className="flex items-center text-primary mb-2">
              <span className="h-4 w-4 bg-primary rounded-full mr-2"></span>
              Recover asset in one week           </li>
            <li className="flex items-center text-primary mb-2">
              <span className="h-4 w-4 bg-primary rounded-full mr-2"></span>
              Cancel contract at any time            </li>
            <li className="flex items-center text-primary mb-2">
              <span className="h-4 w-4 bg-primary rounded-full mr-2"></span>
              Top tier data protection
            </li>
          </ul>
          <button className="py-4 px-6 bg-primary text-white text-lg mb-4 rounded-md">Let's Talk</button>
        </div>
      </div>
    </div>
  </div>
</section>



<section className="py-4">
  <div className="container mx-auto px-4">
    <div className="flex flex-col lg:flex-row justify-center items-center">
      {/* Column 1: Image (w-2/5 on large screens, hidden on small screens) */}
      <div className="w-full lg:w-2/5 p-4 mb-4 lg:mb-0">
        <img
          src="faq.webp"
          alt="Crypto Image"
          className="w-full h-auto"
        />
      </div>

      {/* Column 2: Text Content (w-full on small screens, w-1/2 on large screens) */}
      <div className="w-full lg:w-1/2 p-4">
        <FAQ />
      </div>
    </div>
  </div>
</section>

    </>
  );
};

export default Pricing;