/** @format */

import React from "react";
import { Link } from "react-router-dom";

const Blog = () => {

    const blogPosts = [
        {
          id: 1,
          title: "Bitcoin Vs LiteCoin: Which is the Best?",
          author: "John Smith",
          date: "19 Jan 2023",
          imageUrl: "./bitcoinblog.jpg",
          // imageUrl: require("./bitcoinrecover.png"), // Importing the local image

          excerpt: "When comparing Bitcoin and Litecoin, both have their strengths. Bitcoin is like the big brother, more established and widely accepted, while Litecoin is seen as the silver to Bitcoin's gold, offering faster transaction speeds. It really depends on your preferences and what you're looking to achieve with your crypto investments. Feel free to ask if you want more details on either of them!          ",
        },
        {
            id: 2,
            title: "Why Bitcoin is become popular in this day?            ",
            author: "George Norman",
            date: "16 Nov 2023",
            imageUrl: "./blog3.jpeg",
            excerpt:"Bitcoin has gained immense popularity in today's world due to several key factors. Firstly, its decentralized nature appeals to many individuals who value financial independence and control over their assets. Additionally, the limited supply of 21 million coins creates scarcity, driving up demand and value. ",
          },

          {
            id: 3,
            title: "The size of the total cryptocurrency market share            ",
            author: "Micheal Jordan",
            date: "4 Jan 2024",
            imageUrl: "./litecoinimg.jpeg",
            excerpt: "The total cryptocurrency market share refers to the combined value of all cryptocurrencies in circulation. As of now, the cryptocurrency market has experienced significant growth and fluctuation. The total market capitalization of all cryptocurrencies combined can vary daily due to price changes of individual coins. ",
          },

          {
            id: 4,
            title: "Why Ethereum is important for us?            ",
            author: "Regina Jones",
            date: "5 March Jan 2022",
            imageUrl: "./ethe.webp",
            excerpt: "Ethereum holds significant importance in the world of cryptocurrencies and blockchain technology. One of the key reasons Ethereum is crucial is its smart contract functionality. Smart contracts are self-executing contracts with the terms of the agreement directly written into code. This feature enables a wide range of applications beyond simple peer-to-peer transactions, including decentralized finance (DeFi), non-fungible tokens (NFTs), and decentralized applications (dApps). ",
          },

          {
            id: 5,
            title: "Is it possible to recover Bitcoin without any hassle?            ",
            author: "Meghan",
            date: "19 Jan 2024",
            imageUrl: "./bitccoinrecovery.jpeg",
            excerpt: "Recovering Bitcoin can be a straightforward process if you have the necessary information and access to your wallet or account. However, if you lose your private keys or access to your wallet, the process can become challenging and sometimes impossible.            ",
          },

          {
            id: 6,
            title: "The size of the capital raise Strategy.            ",
            author: "Jane Meet",
            date: "16 Jan 2023",
            imageUrl: "./blog6.jpeg",
            excerpt: "The size of the capital raise strategy is a critical aspect for businesses looking to secure funding for various initiatives. When determining the size of a capital raise, companies need to consider several factors such as their current financial needs, growth projections, market conditions, and the intended use of the funds.       ",
          },
  
      ];
    
  return (
    <>
      <div
        class="min-h-[40vh] flex items-center bg-secondary bg-cover bg-center"
        style={{
          backgroundImage: "url(./about.webp)",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          justifyContent: "flex-start",
        }}
      >
        <div class="text-white text-left px-12 py-2">
          <h1 class="md:text-6xl text-3xl font-bold mb-4 mt-8">Blog</h1>
          <div className="flex space-x-6">
            {" "}
            <a href="/" class="text-xl text-white no-underline  mb-4 block">
              Home
            </a>
            <div class="text-4xl"> → </div>
            <a href="/blog" class="text-xl text-white no-underline mb-4 block">
              Blog
            </a>
          </div>
        </div>
      </div>
      <div>
        <h2 className="md:text-6xl text-4xl text-black font-bold text-start p-6 container mb-6">
          Blog Post
        </h2>
        <h4 className="md:text-xl text-lg text-black  text-start   container w-3/5 mb-6">
          With our integrated CRM, project management, collaboration and
          invoicing capabilities, you can manage your business in one secure
          platform.
        </h4>
      </div>


      <div className="container mx-auto py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Map through blogPosts array and render each post as a card */}
          {blogPosts.map((post) => (
            <div key={post.id} className="bg-white rounded-lg shadow-md overflow-hidden">
              <div className="relative overflow-hidden">
                <img
                  src={post.imageUrl}
                  alt={post.author}
                  className="w-full h-48 object-cover transition-transform transform hover:scale-105"
                />
              </div>
              <div className="p-4">
                <h2 className="text-lg font-bold mb-2">{`${post.author} - ${post.date}`}</h2>
                <h3 className="text-xl font-bold mb-4">{post.title}</h3>
                <p className="text-gray-700 mb-4">{post.excerpt}</p>
                {/* Use Link component for navigation to BlogDetails */}
                <Link to={`/blog/${post.id}`} className="text-primary hover:text-primary font-bold">
                  Read More
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>


  );
};

export default Blog;
