import React from "react";
import Accordion from "./Accordion";

const FAQ = () => {
  return (
    <div className="p-12 rounded-lg">
        <div className="border-b">
                  <Accordion
        title="How does it work?"
        answer='First of all, welcome to Expart Recovery. To initiate the process, kindly reach out to the administrator either through the chatbox or by clicking on the "Contact Us" button to provide the necessary information.'
      /> 
      </div>
<div  className="border-b">     
 <Accordion
        title="How can I trust you ? "
answer='At Expart Recovery, we understand the importance of trust in our services. Rest assured, we prioritize transparency, security, and professionalism in all our interactions. Our track record of successful outcomes and satisfied clients speaks to our commitment to reliability and integrity. If you have any specific concerns or questions, feel free to reach out, and we will be more than happy to address them.'      />
      </div>
<div className="border-b">
          <Accordion title="Are you legit - will you steal my funds?"
           answer="At Expart Recovery, we operate with the utmost legitimacy and integrity. Your funds are secure with us, and we prioritize the safety and confidentiality of our clients' assets. We adhere to strict ethical standards and take every measure to ensure the protection of your funds throughout our engagement. If you have any further inquiries or require additional reassurance, please do not hesitate to contact us for more information." />
          </div>

    </div>
  );
};

export default FAQ;