/** @format */

import React from "react";
import { FaLocationArrow, FaPhone, FaPhoneAlt, FaVoicemail } from "react-icons/fa";
import { FaLocationPinLock } from "react-icons/fa6";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";

const Contact = () => {
  return (
    <>
      <div
        class="min-h-[40vh] flex items-center bg-secondary bg-cover bg-center"
        style={{
          backgroundImage: "url(./about.webp)",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          justifyContent: "flex-start",
        }}
      >
        <div class="text-white text-left px-12 py-2">
          <h1 class="text-4xl font-bold mb-4 mt-8">Contact Us</h1>
          <div className="flex space-x-6">
            {" "}
            <a href="/" class="text-xl text-white no-underline  mb-4 block">
              Home
            </a>
            <div class="text-4xl"> → </div>
            <a
              href="/pricing"
              class="text-xl text-white no-underline mb-4 block"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>

      <div class="container px-4 mb-12">
        <div class=" mb-8 ">
          <h2 class="text-4xl font-bold text-secondary mt-8">Contact Us</h2>
          <p class="text-lg text-secondary mt-4">
            Contact us for a swift response
          </p>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div class="space-y-8">
            <div class="flex items-center space-x-4">
              <div class="w-8 h-8 bg-primary rounded-full flex justify-center items-center">
                <span class="text-white">
                  <FaVoicemail />
                </span>
              </div>
              <p>support@recoalign.com</p>
            </div>
            <div class="flex items-center space-x-4">
              <div class="w-8 h-8 bg-primary rounded-full flex justify-center items-center">
                <span class="text-white">
                  <FaPhoneAlt />
                </span>
              </div>
              <p>+19452060419</p>
            </div>

            <div class="flex items-center space-x-4">
              <div class="w-8 h-8 bg-primary rounded-full flex justify-center items-center">
                <span class="text-white">
                  <FaLocationPinLock />
                </span>
              </div>
              <p>213 Lane, London, United Kingdom</p>
            </div>
          </div>

          <div class="w-full">
            <form class="shadow-lg p-8 rounded-md">
              <div class="grid grid-cols-1  gap-8">
                <div>
                  <div class="mb-4">
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      class="w-full border rounded-md py-2 px-3 text-gray-800"
                      required
                      placeholder="First Name"
                    />
                  </div>
                  <div class="mb-4">
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      class="w-full border rounded-md py-2 px-3 text-gray-800"
                      required
                      placeholder="Last Name"
                    />
                  </div>
                  <div class="mb-4">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      class="w-full border rounded-md py-2 px-3 text-gray-800"
                      required
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div class="md:col-span-2">
                  <div class="mb-8">
                    <textarea
                      id="message"
                      name="message"
                      rows="4"
                      class="w-full border rounded-md py-2 px-3 text-gray-800"
                      required
                      placeholder="Message"
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    class="w-full bg-primary text-white py-3 px-6 rounded-md hover:bg-primary-dark transition-colors duration-300"
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="w-full">
        <MapContainer
          center={[37.7749, -122.4194]}
          zoom={13}
          style={{ height: "400px" }}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker position={[37.7749, -122.4194]}>
            <Popup>A sample popup for the marker.</Popup>
          </Marker>
        </MapContainer>
      </div>
    </>
  );
};

export default Contact;
